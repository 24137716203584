import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";

import Container from "../../components/Container";

import { ReactComponent as OrderHistoryIcon } from "../../assets/images/order_history.svg";
import { ReactComponent as SalesIcon } from "../../assets/images/sales.svg";
import { ReactComponent as StaffMgmtIcon } from "../../assets/images/staff_mgmt.svg";
import { ReactComponent as FloorMgmtIcon } from "../../assets/images/floor_mgmt.svg";
import { ReactComponent as AccountDetailIcon } from "../../assets/images/account_detail.svg";
import { ReactComponent as EventsIcon } from "../../assets/images/events.svg";
import { ReactComponent as ManageMenuIcon } from "../../assets/images/manage_menu.svg";
import { ReactComponent as OrderTimeIcon } from "../../assets/images/order_time.svg";
import { ReactComponent as RestInfoIcon } from "../../assets/images/rest_info.svg";
import { ReactComponent as SubscriptionIcon } from "../../assets/images/subscription.svg";
import { ReactComponent as CoursesIcon } from "../../assets/images/courses.svg";
import { ReactComponent as RestSettingIcon } from "../../assets/images/rest_setting.svg";
import { ReactComponent as OfflineIcon } from "../../assets/images/offline.svg";
import { ReactComponent as PrinterIcon } from "../../assets/images/printer.svg";
import { ReactComponent as AnalyticsIcon } from "../../assets/images/analytics.svg";
import { ReactComponent as TimeIcon } from "../../assets/images/time.svg";

import { THEME_MODE } from "../../constants/Theme";
import {
  APP_TYPE_BOTH,
  APP_TYPE_GOPOSH,
  CONTAINER_HEIGHT,
  FOOTER_XPADDING,
} from "../../constants";

let ICON_SIZE = "2.1rem";
let CONTENT_HEIGHT = CONTAINER_HEIGHT - 60;

const settings_list = [
  {
    page: "order_history",
    label: "Order History",
    href: "/order_history",
    icon: <OrderHistoryIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    page: "sales",
    label: "Sales",
    href: "/sales_report",
    icon: <SalesIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    page: "floor_management",
    label: "Floor Management",
    href: "/floor_management",
    icon: <FloorMgmtIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    page: "account_details",
    label: "Account Details",
    href: "/account_detail",
    icon: <AccountDetailIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    page: "order_time_settings",
    label: "Order Time Settings",
    href: "/order_time",
    icon: <OrderTimeIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    page: "bookings_history",
    label: "Bookings History",
    href: "/bookings/history",
    icon: <RestSettingIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    page: "offline",
    label: "Offline",
    href: "/offline",
    icon: <OfflineIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    page: "printers",
    label: "Printers",
    href: "/printers",
    icon: <PrinterIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    page: "analytics",
    label: "Analytics",
    href: "/analytics",
    icon: <AnalyticsIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
];
const foodo_settings_list = [
  {
    page: "staff_management",
    label: "Staff  Management",
    href: "/users",
    icon: <StaffMgmtIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    page: "events",
    label: "Events",
    href: "/events",
    icon: <EventsIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    page: "manage_menu",
    label: "Manage Menu",
    href: "/settings/menu",
    icon: <ManageMenuIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    page: "restaurant_info",
    label: "Restaurant Info",
    href: "/restaurant_info",
    icon: <RestInfoIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    page: "custom_restaurant_info",
    label: "Portal Settings",
    href: "/custom_restaurant_info",
    icon: <RestInfoIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    page: "subscriptions",
    label: "Subscriptions",
    href: "/subscriptions",
    icon: <SubscriptionIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    page: "courses",
    label: "Courses",
    href: "/course",
    icon: <CoursesIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    page: "modifiers",
    label: "Modifiers",
    href: "/modifiers",
    icon: <CoursesIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    page: "restaurant_settings",
    label: "Restaurant Settings",
    href: "/restaurant_settings",
    icon: <RestSettingIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    page: "timer_register",
    label: "Timer Register",
    href: "/timer_register",
    icon: (
      <Box sx={{ path: { fill: "#FFF" } }}>
        <TimeIcon width={ICON_SIZE} height={ICON_SIZE} />
      </Box>
    ),
  },
  {
    page: "takeaway_settings",
    label: "TakeAway Settings",
    href: "/takeaway_settings",
    icon: <RestSettingIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
];

const settingsOrder = [
  "order_history",
  "sales",
  "staff_management",
  "floor_management",
  "account_details",
  "events",
  "manage_menu",
  "order_time_settings",
  "takeaway_settings",
  "restaurant_info",
  "custom_restaurant_info",
  "subscriptions",
  "courses",
  "modifiers",
  "restaurant_settings",
  "bookings_history",
  "offline",
  "printers",
  "analytics",
  "timer_register",
];

const Settings = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);
  const navigate = useNavigate();

  const [settingsList, setSettingsList] = useState(foodo_settings_list);

  useEffect(() => {
    const appType = props.restaurantDetail?.appType;
    let list = settingsOrder
      .map(function (v) {
        return (
          (appType === APP_TYPE_GOPOSH || appType === APP_TYPE_BOTH
            ? [...foodo_settings_list, ...settings_list]
            : foodo_settings_list
          ).filter((x) => x.page == v)?.[0] ?? null
        );
      })
      .filter((y) => y != null);
    setSettingsList(list);
  }, []);

  return (
    <Container
      page={"settings"}
      navTitle={"Settings"}
      p={1.5}
      minHeight={CONTENT_HEIGHT}
      fpx={FOOTER_XPADDING[props.themeMode]}
      fpy={0}
    >
      <Grid container item xs={12} md={10} sx={styles.settingsBtnMainDiv}>
        {settingsList.map((x, i) => {
          return (
            <Grid key={i} item xs={2.4}>
              <Box sx={styles.settingsBtnDiv} onClick={() => navigate(x.href)}>
                <Box>{x.icon}</Box>
                <Typography sx={styles.settingsBtnDivText}>
                  {x.label}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
    restaurantDetail: state.userData.restaurantDetail,
  };
};

export default connect(mapStateToProps, null)(Settings);

const styles1 = (Theme) => ({
  settingsBtnMainDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  settingsBtnDiv: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Theme.settingsCardBg,
    borderRadius: "8px",
    cursor: "pointer",
    flex: 1,
    height: CONTENT_HEIGHT / 4.3,
    maxHeight: 150,
    px: 1.5,
    py: 1.3,
    mb: "8px",
    mr: "8px",
  },
  settingsBtnDivText: {
    color: Theme.btnText,
    fontSize: { md: "1.1rem", xl: "1.3em" },
    fontFamily: "InterBold",
    textAlign: "center",
  },
});
