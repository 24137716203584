import React, { forwardRef, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import moment from "moment";

import SelectizeTextField from "./SelectizeTextField";
import SwitchInput from "../containers/Settings/SwitchInput";
import SettingTimePicker from "./SettingTimePicker";
import SettingTextField from "./SettingTextField";

import { THEME_MODE } from "../constants/Theme";
import { VAT_NUM_VAL, VAT_VAL } from "../constants";

import "./../assets/css/custom.css";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const APPLY_VAT_LIST = [
  "Apply VAT automatically in billing",
  "Apply VAT manually in billing",
];

const AddRestaurantInfo = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const theme = createTheme({
    components: {
      MuiPopover: {
        styleOverrides: {
          root: {
            "& .MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper":
              {
                backgroundColor: theme_mode.card,
                maxHeight: 300,
              },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            "& .MuiSvgIcon-root": {
              color: theme_mode.iconBg2,
            },
          },
        },
      },
    },
    palette: {
      gray: {
        main: theme_mode.text4,
      },
      yellow: {
        main: theme_mode.theme,
      },
    },
  });

  const [category, setCategory] = useState("");
  const [option, setOption] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileCode, setMobileCode] = useState(null);
  const [mobile, setMobile] = useState("");
  const [desc, setDesc] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [vatVal, setVatVal] = useState(VAT_VAL);
  const [vatNum, setVatNum] = useState(VAT_NUM_VAL);
  const [vatRegNum, setVatRegNum] = useState("");
  const [vatEnabled, setVatEnabled] = useState(false);
  const [applyVatVal, setApplyVatVal] = useState(APPLY_VAT_LIST[0]);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    let list = [];
    getCountries().map((x) => {
      let code = getCountryCallingCode(x);
      list.push({
        country: x,
        code: `+${code}`,
        name: `${x} (+${code})`,
      });
    });

    let filter = list.filter((x) => x.country == props.countryCode);
    if (filter.length) {
      let data = filter[0];
      setMobileCode(data);
      let code1 = data.code;
      if (props.mobile.includes(code1, 0)) {
        setMobile(props.mobile.replace(code1, ""));
      }
    } else {
      let filter1 = list.filter((x) => x.code == "+1");
      let filter2 = list.filter((x) => x.code == "+44");
      if (filter1.length && props.mobile.includes("+1", 0)) {
        setMobileCode(filter1[0]);
        setMobile(props.mobile.replace("+1", ""));
      } else if (filter2.length && props.mobile.includes("+44", 0)) {
        setMobileCode(filter2[0]);
        setMobile(props.mobile.replace("+44", ""));
      } else {
        setMobile(props.mobile.replace("+", ""));
      }
    }
    setCountryList(list);
  }, [props.countryCode, props.mobile]);

  useEffect(() => {
    setName(props.name);
    setEmail(props.email);
    setDesc(props.desc);
    setCategory(props.category);
    setOption(props.option);
    setVatVal(props?.vat);
    setVatNum(props?.vatNum);
    setVatEnabled(props?.vat > 0);
  }, [props]);

  const onCloseAlertMsg = () => {
    setMsgAlert({ open: false, message: "", msgType: "error" });
  };

  const onCheckboxClick = (val) => {
    setOption(val);
  };

  const onNextBtnClick = (e) => {
    e.preventDefault();

    if (props.type == "add") {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (reg.test(email) == false) {
        setMsgAlert({ open: true, message: "Invalid Email", msgType: "error" });
        return;
      }
    }

    if (
      mobileCode == null ||
      typeof mobileCode?.code == "undefined" ||
      typeof mobileCode?.country == "undefined"
    ) {
      setMsgAlert({
        open: true,
        message: "Mobile Code is required",
        msgType: "error",
      });
      return;
    }

    if (/[^0-9]/g.test(mobile)) {
      setMsgAlert({
        open: true,
        message: "Invalid Contact Number",
        msgType: "error",
      });
      return;
    }

    if (mobile.length < 5) {
      setMsgAlert({
        open: true,
        message: "Minimum length 5 is required for Contact Number",
        msgType: "error",
      });
      return;
    }

    if (vatEnabled && vatNum <= 0) {
      setMsgAlert({
        open: true,
        message: "VAT number is required",
        msgType: "error",
      });
      return;
    }

    if (vatEnabled && vatVal <= 0) {
      setMsgAlert({
        open: true,
        message: "VAT in % is required",
        msgType: "error",
      });
      return;
    }

    if (vatEnabled && vatRegNum === "") {
      setMsgAlert({
        open: true,
        message: "VAT registration number is required",
        msgType: "error",
      });
      return;
    }

    let data = {
      name: name,
      email: email,
      countryCode: mobileCode?.country,
      mobile: mobileCode?.code + "" + mobile,
      desc: desc,
      vat: vatVal,
      vatNum: vatNum,
      option: option,
      category: category,
    };
    props.resInfo(data);
  };

  const handlerOnChange = (type, value) => {
    if (type === "description") {
      setDesc(value);
    } else if (type === "category") {
      setCategory(value);
    } else if (type === "vat") {
      setVatVal(value.replace(/[^0-9]/g, ""));
    } else if (type === "vatNum") {
      setVatNum(value.replace(/[^0-9.]/g, ""));
    } else if (type === "vatRegNum") {
      setVatRegNum(value);
    } else if (type === "applyVat") {
      setApplyVatVal(value);
    }
  };

  const handlerOnVatChange = () => {
    setVatEnabled(!vatEnabled);
    setVatVal(vatEnabled ? 0 : VAT_VAL);
    setVatNum(vatEnabled ? 0 : VAT_NUM_VAL);
  };

  return (
    <Grid component="form" autoComplete="off" onSubmit={onNextBtnClick}>
      <Grid
        container
        direction="row"
        item
        xs={12}
        justifyContent="space-between"
      >
        <Grid item xs={12} md={5.3} className="restaurant-first-data-grid">
          <Grid>
            <ThemeProvider theme={theme}>
              <TextField
                required
                focused
                fullWidth
                id="name"
                label="Name"
                color="gray"
                variant="standard"
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputLabelProps={{
                  sx: { ...styles.inputLabelText },
                }}
                inputProps={{
                  autoComplete: "none",
                  sx: { ...styles.textInputValue },
                }}
              />
            </ThemeProvider>
          </Grid>
          {props.type == "add" ? (
            <Grid className="restaurant-data-grid">
              <ThemeProvider theme={theme}>
                <TextField
                  required
                  focused
                  fullWidth
                  id="email"
                  label="Email"
                  color="gray"
                  type="email"
                  variant="standard"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputLabelProps={{
                    sx: { ...styles.inputLabelText },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: { ...styles.textInputValue },
                  }}
                />
              </ThemeProvider>
            </Grid>
          ) : null}
          <Grid className="restaurant-data-grid">
            <label className="restaurant-label" style={styles.labelText}>
              Contact Number *
            </label>
            <ThemeProvider theme={theme}>
              <Grid
                container
                direction="row"
                style={{ alignItems: "flex-end" }}
              >
                <Grid item xs={4.5}>
                  <SelectizeTextField
                    page={"AddRestaurantInfo"}
                    tagColor={theme_mode.inputBg5}
                    multiple={false}
                    value={mobileCode}
                    onChange={(val) => {
                      setMobileCode(val);
                    }}
                    backgroundColor={"transparent"}
                    options={countryList}
                    variant={"filled"}
                    label={""}
                    placeholder={""}
                    borderBottom={"2px solid #FFF"}
                    borderRadius={"0px"}
                    disableClearable={true}
                  />
                </Grid>
                <Grid item xs={7.5}>
                  <Input
                    required
                    disableUnderline
                    id="contactNumber"
                    color="gray"
                    sx={{ ...styles.selectBox, width: "100%" }}
                    value={mobile}
                    onChange={(e) =>
                      setMobile(e.target.value.replace(/[^0-9]/g, ""))
                    }
                    inputProps={{
                      autoComplete: "none",
                      pl: 1,
                      sx: {
                        "&::placeholder": {
                          fontSize: 13,
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </ThemeProvider>
          </Grid>

          <Grid item xs={12} className="restaurant-data-grid">
            <Box style={styles.vatLabelView}>
              <label className="restaurant-label" style={styles.labelText}>
                Is VAT applicable?
              </label>
              <Box ml={1}>
                <SwitchInput
                  checked={vatEnabled}
                  onChange={handlerOnVatChange}
                />
              </Box>
            </Box>
            {vatEnabled ? (
              <>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12} md={6} mt={1}>
                    <SettingTextField
                      page={"AddRestaurantInfo"}
                      value={vatNum}
                      setValue={(val) => handlerOnChange("vatNum", val)}
                      backgroundColor={"transparent"}
                      borderRadius={"0"}
                      label={"VAT Number"}
                      placeholder={""}
                      required={vatEnabled}
                      labelStyle={styles.labelText}
                      textInputCss={styles.textInputCss}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} mt={1}>
                    <SettingTextField
                      page={"AddRestaurantInfo"}
                      value={vatVal}
                      setValue={(val) => handlerOnChange("vat", val)}
                      backgroundColor={"transparent"}
                      borderRadius={"0"}
                      label={"VAT in %"}
                      placeholder={""}
                      required={vatEnabled}
                      labelStyle={styles.labelText}
                      textInputCss={styles.textInputCss}
                    />
                  </Grid>
                </Grid>
                <Box mt={1.5}>
                  <SettingTextField
                    page={"AddRestaurantInfo"}
                    value={vatRegNum}
                    setValue={(val) => handlerOnChange("vatRegNum", val)}
                    backgroundColor={"transparent"}
                    borderRadius={"0"}
                    label={"VAT Registration Number"}
                    placeholder={""}
                    required={vatEnabled}
                    labelStyle={styles.labelText}
                    textInputCss={styles.textInputCss}
                  />
                </Box>
                <Box mt={3}>
                  <ThemeProvider theme={theme}>
                    <Select
                      required
                      fullWidth
                      disableUnderline
                      sx={styles.selectBox}
                      color="gray"
                      variant="standard"
                      value={applyVatVal}
                      onChange={(event) =>
                        handlerOnChange("applyVat", event.target.value)
                      }
                    >
                      {APPLY_VAT_LIST.map((item, index) => (
                        <MenuItem
                          sx={styles.menuItemText}
                          divider={index < 1}
                          key={index}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </ThemeProvider>
                </Box>
              </>
            ) : null}
          </Grid>
        </Grid>

        <Grid item xs={12} md={5.3} className="restaurant-second-data-grid">
          <Grid>
            <label className="restaurant-label" style={styles.labelText}>
              Category
            </label>
            <ThemeProvider theme={theme}>
              <Select
                required
                fullWidth
                disableUnderline
                sx={styles.selectBox}
                color="gray"
                variant="standard"
                value={category}
                onChange={(event) =>
                  handlerOnChange("category", event.target.value)
                }
              >
                {props.categoryList.map((item, index) => (
                  <MenuItem
                    sx={styles.menuItemText}
                    divider={index < props.categoryList.length - 1}
                    key={index}
                    value={item._id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </ThemeProvider>
          </Grid>
          <Grid className="restaurant-data-grid">
            <ThemeProvider theme={theme}>
              <TextField
                required
                multiline
                fullWidth
                focused
                id="description"
                label="Description"
                color="gray"
                rows={5}
                variant="standard"
                value={desc}
                onChange={(e) => handlerOnChange("description", e.target.value)}
                InputLabelProps={{
                  sx: styles.inputLabelText,
                }}
                inputProps={{
                  autoComplete: "none",
                  sx: styles.textInputValue,
                }}
              />
            </ThemeProvider>
          </Grid>
          <Grid className="restaurant-data-grid">
            <label className="restaurant-label" style={styles.labelText}>
              Options
            </label>
            <ThemeProvider theme={theme}>
              <FormGroup className="restaurant-main-option">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      sx={{ color: theme_mode.text3 }}
                      color="yellow"
                      checked={option === "dine_in"}
                      onChange={() => onCheckboxClick("dine_in")}
                    />
                  }
                  label={
                    <Typography sx={styles.optionLabel}>Dine in</Typography>
                  }
                  className="restaurant-option"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      sx={{ color: theme_mode.text3 }}
                      color="yellow"
                      checked={option === "take_away"}
                      onChange={() => onCheckboxClick("take_away")}
                    />
                  }
                  label={
                    <Typography sx={styles.optionLabel}>Take Away</Typography>
                  }
                  className="restaurant-option"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      sx={{ color: theme_mode.text3 }}
                      color="yellow"
                      checked={option === "both"}
                      onChange={() => onCheckboxClick("both")}
                    />
                  }
                  label={<Typography sx={styles.optionLabel}>Both</Typography>}
                />
              </FormGroup>
            </ThemeProvider>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt={7}
        mb={1}
      >
        <Button
          type="submit"
          sx={{
            backgroundColor: theme_mode.theme,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            textTransform: "capitalize",
            color: theme_mode.text,
            fontFamily: "InterBold",
            fontSize: 13,
            borderRadius: 1,
            width: 100,
            height: 35,
            "&:hover": {
              backgroundColor: theme_mode.theme + " !important",
            },
          }}
        >
          Next
        </Button>
      </Grid>

      <Snackbar
        open={msgAlert.open}
        autoHideDuration={6000}
        onClose={onCloseAlertMsg}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={onCloseAlertMsg}
          severity={msgAlert.msgType}
          sx={{ width: "100%" }}
        >
          {msgAlert.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default AddRestaurantInfo;

const styles1 = (Theme) => ({
  labelText: {
    fontSize: 12,
    color: Theme.inputLabel,
    fontFamily: "InterRegular",
  },
  inputLabelText: {
    color: `${Theme.inputLabel} !important`,
    fontFamily: "InterRegular",
  },
  textInputValue: {
    color: Theme.inputVal,
    fontSize: 14,
    fontFamily: "InterSemiBold",
  },
  textInputCss: {
    "& .MuiInputBase-root": {
      height: 29,
      borderBottom: `2px solid ${Theme.borderBottom2}`,
      "&:before, :after, :hover:not(.Mui-disabled):before": {
        borderBottom: 0,
      },
    },
  },
  vatLabelView: {
    display: "flex",
    alignItems: "center",
  },
  selectBox: {
    color: Theme.inputVal,
    borderBottom: "2px solid " + Theme.borderBottom2,
    fontSize: 14,
    fontFamily: "InterSemiBold",
  },
  menuItemText: {
    fontSize: 13,
    fontFamily: "InterSemiBold",
    color: Theme.inputVal,
    "&.MuiMenuItem-divider": {
      borderBottom: "1px solid #DDD",
    },
    "&:hover": {
      backgroundColor: Theme.inputBg2,
      color: Theme.text,
    },
    "&.Mui-selected:hover": {
      backgroundColor: Theme.inputBg2,
      color: Theme.text,
    },
    "&.Mui-selected": {
      backgroundColor: Theme.inputBg5,
      color: Theme.text,
    },
  },
  optionLabel: {
    color: Theme.inputLabelText,
    fontSize: 13,
    fontFamily: "InterSemiBold",
  },
});
